import './App.css';
import Home from './components/home/Home';
import Policy from './components/policy/Policy'
import { Route, HashRouter, Switch, Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import React from 'react';
import Web3 from 'web3';
import ReactTooltip from 'react-tooltip';
import $ from 'jquery';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';


import { ADDRESS_MAINNET, ADDRESS_TESTNET } from './config';

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      web3: null,
      networkType: null,
      chainId: null,
      account: null,
      isEnabled: false,
      isConnecting: false,
      optionType: '',
      LIST_ADDRESS_STAKE: null,
      canShow: true,
      maticUrl: 'https://polygonscan.com/address/',
    };

    toastr.options = {
      // positionClass: 'toast-top-full-width',
      hideDuration: 300,
      timeOut: 3000,
    };
  }

  resetPage() {
    setTimeout(() => {
      window.location.reload();
    })
  }

  async connectToMetaMaskNetwork(type) {
    if (this.state.isConnecting) {
      return;
    }
    console.log("CONNECT::::")
    this.setState({ isConnecting: true });
    if (window.ethereum) {
      await window.ethereum.enable().then((err, res) => {
        console.log("Res")
      });
      this.state.web3 = new Web3(window.ethereum);
      window.ethereum.on('networkChanged', (chainId) => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      });
      window.ethereum.on('chainChanged', (chainId) => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      });
      window.ethereum.on('accountsChanged', (chainId) => {
        // handle the new network
        document.location.reload();
      });
      window.ethereum.on('disconnect', (chainId) => {
        document.location.reload();
      });
      try {
        // console.log("YES:::")
        this.setState({ optionType: type });
        await this.setConfig();
      } catch (e) {
        // User has denied account access to DApp...
        // console.log("ERROR:::::::::::::::::::::", e)
        await this.setState({ isEnabled: false });
      }
    } else if (window.web3 && window.web3.currentProvider) {
      // console.log("DONE::")
      window.web3.on('networkChanged', (chainId) => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      });
      window.web3.on('chainChanged', (chainId) => {
        // handle the new network
        // document.location.reload();
        this.setConfig();
      });
      window.web3.on('accountsChanged', (chainId) => {
        // handle the new network
        document.location.reload();
      });
      window.web3.on('disconnect', (chainId) => {
        document.location.reload();
      });
      this.state.web3 = new Web3(window.web3.currentProvider);
      try {
        this.setState({ optionType: type });
        await this.setConfig();
      } catch (e) {
        // User has denied account access to DApp...
        // console.log("ERROR:::::::::::::::::::::")
        await this.setState({ isEnabled: false, isConnecting: false });
        this.changesReflactor();
      }
    } else {
      await this.setState({ isEnabled: false, isConnecting: false });
      this.changesReflactor();
    }
  }

  async setConfig() {
    // console.log("Network changed")
    const Id = await this.state.web3.eth.net.getNetworkType();
    const chainId = await this.state.web3.eth.getChainId();
    const accounts = await this.state.web3.eth.getAccounts();
    this.state.web3.eth.defaultAccount = accounts[0];
    this.configNetwork(Id, chainId, accounts[0]);
  }

  async configNetwork(Id, chainId, account) {
    if (chainId != 137 && chainId != 80001) {
      toastr.info('Wrong network choosen.');
      await this.setState({
        isEnabled: false,
        isConnecting: false,
      });
      this.changesReflactor();
      return;
    } else if (chainId == 137 || chainId == 80001) {
      let ADDRESS_MAIN = null;
      let maticUrl = "";
      if (chainId === 80001) {
        Id = "MATIC Testnet";
        ADDRESS_MAIN = ADDRESS_TESTNET;
        maticUrl = 'https://mumbai.polygonscan.com/address/';
      } else if (chainId === 137) {
        Id = "MATIC Mainnet";
        ADDRESS_MAIN = ADDRESS_MAINNET;
        maticUrl = 'https://polygonscan.com/address/';
      }

      await this.setState({
        isEnabled: true,
        networkType: Id,
        chainId: chainId,
        account: account,
        maticUrl : maticUrl,
        ADDRESS_MAIN: ADDRESS_MAIN
      });
      this.changesReflactor();
      // await this.setSmartContract();
    } else {
      this.changesReflactor();
    }
  }

  async changesReflactor() {
    await this.setState({ canShow: false });
    await this.setState({ canShow: true });
  }

  render() {
    return (

      <HashRouter hashType='slash'>
        <header id="header" className="fixed-top">
          <div className="social">
            <ul>
              <li><a href="https://twitter.com/MultisendOrg" target="_blank"><i className="icofont-twitter"></i></a></li>
              <li><a href="https://t.me/joinchat/i1CqopIpvXk3MTk0" target="_blank"><i className="icofont-telegram"></i></a></li>
              <li><a href="https://www.youtube.com/channel/UCuwDPenNaVTi8AOTcxhPvCQ" target="_blank"><i className="icofont-youtube-play"></i></a></li>
              <li><a href="https://medium.com/@polygonsender.com" target="_blank"><img src="assets/images/medium.png" style={{ width: '30px', marginTop: '-4px' }} ></img></a></li>
              <li><a href={this.state.maticUrl + ADDRESS_MAINNET} target="_blank"><i className="icofont-database"></i></a></li>
            </ul>
          </div>
          <div className="container d-flex align-items-center">
            <Nav.Link as={Link} to="/" onClick={() => this.resetPage()} className="logo ml-auto mr-auto">
              <img class="group" src="assets/images/group@2x.svg" />
              <span class="montserrat-normal-white-36px-2">POLYGONSENDER</span>
            </Nav.Link>
          </div>

          <div className="connect">
            <ul>
              <li>{this.state.isEnabled ? (
                <div id="networkContainer" style={{ display: 'block' }}>
                  <span className="liveNetwork"></span>
                  <span className="networkId"> {this.state.networkType} </span>
                  <br />
                  <span><a href={this.state.maticUrl + this.state.account} target="_blank" style={{ fontSize: '13px' }}>{this.state.account}</a></span>
                </div>
              ) : ''}</li>
              <li>{this.state.isEnabled ? '' : (
                <button className="btn-gen animated fadeInUp glow-on-hover" onClick={() => this.connectToMetaMaskNetwork()}><span className="glow-on-hover2"></span>Connect Wallet</button>
              )}</li>
            </ul>
          </div>
        </header>
        {this.state.canShow ? (
          <Switch>
            <Route exact path='/' render={(props) => (<Home values={this.state}></Home>)}></Route>
            <Route exact path='/policy' render={(props) => (<Policy values={this.state}></Policy>)}></Route>
            {/* <Route exact path="/policy" component={() => <Policy values={prop.data} />} /> */}
          </Switch>
        ) : (
          ''
        )}
        <footer id="footer">
          <div className="container">
            <div className="copyright">
              &copy; Copyright <strong><span>PolygonSender</span></strong> 2021. All Rights Reserved &nbsp;&nbsp;&nbsp;&nbsp;| <Nav.Link as={Link} to="/policy"  className="footer-link" style={{ width: '200px', display: 'inline' }}><span>Privacy Policy</span></Nav.Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;|<Nav.Link as={Link} to="/blogs" className="footer-link" style={{ width: '200px', display: 'inline' }}><span>Blogs</span></Nav.Link>
            </div>
          </div>
        </footer>
      </HashRouter>
    );
  }
}

export default App;
