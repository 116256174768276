import React, { Component } from "react";
import { FileDrop } from 'react-file-drop';
import $ from 'jquery';
import _ from 'lodash';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { BigNumber } from "bignumber.js";
import { LIST_ABI_ERC20, LIST_ABI_MAIN, ADDRESS_MAINNET } from '../../config';
import Web3 from "web3";

const maticChargeValue = 20;
const maticChargePer = 100;
export default class Home extends Component {

    siteUrl = window.location.origin;
    batchCompleteLen = 0;
    batchCompleteList = [];

    constructor(props) {
        super(props);
        this.state = {
            currentScreen: 1,
            tokens: [],
            selectedTokenId: -1,
            selectedTokenDetail: {},
            batchLen: 0,
            csvData: null,
            csvArray: [],
            uploadTotal: 0,
            addresses: [],
            values: [],
            addrCount: maticChargePer,
            calcMatic: maticChargeValue,
            isPageOneBtnDisabled: true,
            isPageTwoBtnDisabled: true,
            isPageThreeBtnDisabled: true,
            isDirectPageThree: true,
            isViewChange: false,
            currentFaq: 1,
            smartContractAddress: '',
        }
        this.fileInput = null;
        this.changeToken = this.changeToken.bind(this);
        this.pageOneNextClick = this.pageOneNextClick.bind(this);
        this.pageTwoNextClick = this.pageTwoNextClick.bind(this);
        this.pageThreeNextClick = this.pageThreeNextClick.bind(this);

        $('#OpenFileUpload').click(function () {
            //console.log("TEST"); $('#file_upload').trigger('click');
        });

        toastr.options = {
            // positionClass: 'toast-top-full-width',
            hideDuration: 300,
            timeOut: 60000
        }
    }

    componentDidMount() {
        this.connectWallet()
    }

    async connectWallet() {
        if (this.props.values.isEnabled) {
            await this.getTokens();
        }
    }

    changeView(isViewChange) {
        this.checkConnection()
        this.setState({
            isPageOneBtnDisabled: true,
            batchLen: 0,
            csvData: null,
            csvArray: [],
            uploadTotal: 0,
            addresses: [],
            values: [],
            duplicateAddr: [],
            invalidRows: [],
            isViewChange: isViewChange
        }, this.canLoad(isViewChange))
    }

    canLoad(isViewChange) {
        if (isViewChange) {
            setTimeout(() => {
                this.append_line_numbers('textarea-num')
            })
        }
    }

    async setToDefaultData() {
        if (this.state.currentScreen == 1) {
            await this.setState({
                currentScreen: 1,
                tokens: [],
                selectedTokenId: -1,
                selectedTokenDetail: {},
                addrCount: maticChargePer,
                calcMatic: maticChargeValue,
                isPageOneBtnDisabled: true,
                isPageTwoBtnDisabled: true,
                isPageThreeBtnDisabled: true,
                isDirectPageThree: true,
            })
        } else {
            await this.setState({
                currentScreen: 1,
                tokens: [],
                selectedTokenId: -1,
                selectedTokenDetail: {},
                batchLen: 0,
                csvData: null,
                csvArray: [],
                uploadTotal: 0,
                addresses: [],
                values: [],
                addrCount: maticChargePer,
                calcMatic: maticChargeValue,
                isPageOneBtnDisabled: true,
                isPageTwoBtnDisabled: true,
                isPageThreeBtnDisabled: true,
                isDirectPageThree: true,
                isViewChange: false,
            })
        }
        if (this.props.values.isEnabled) {
            await this.connectWallet()
        }
    }

    getTokens = async () => {
        //console.log("GET TOKENS:::::")
        if (!this.props.values.account) return;
        if (this.props.values.chainId != 137 && this.props.values.chainId != 80001) return;

        let tokens = [];
        await fetch("https://api.covalenthq.com/v1/" + this.props.values.chainId + "/address/" + this.props.values.account + "/balances_v2/?key=ckey_a4cbc468c97e4ea89569a8ca90c")
            .then(res => res.json())
            .then(
                (resu) => {
                    tokens = resu['data']['items']
                },
                (error) => {
                    tokens = [];
                }
            )
        this.setState({ tokens: tokens })
        // do something with myJson
    }

    handleFileUpload(files, event) {
        if (files.length == 1) {
            var re = /(\.txt|\.csv)$/i;
            if (!re.exec(files[0].name)) {
                toastr.error("File extension not supported! Please upload TXT or CSV file.");
                return;
            }
            const reader = new FileReader();
            reader.onloadend = async () => {
                let csvData = new TextDecoder("utf-8").decode(reader.result);
                // this.inputCSVManually = true;
                await this.setState({
                    batchLen: 0,
                    csvData: null,
                    csvArray: [],
                    uploadTotal: 0,
                    addresses: [],
                    values: [],
                    isViewChange: true,
                });
                await this.onValidateInputs(csvData)
                this.append_line_numbers('textarea-num')
                //console.log(csvData)
                // let array = csvData.split("\n").map((v) => v.split(","));
                // //console.log(array)
            };
            reader.readAsArrayBuffer(files[0]);

            this.fileInput.value = "";
        } else {
            //console.log("Multiple file upload not possible.")
            toastr.error("Multiple file upload not possible.")
        }
    }

    csvDataToArrays(data) {
        return data.split("\n").map((v) => {
            if (v.includes('\r')) {
                v = v.replace(/(\r\n|\n|\r)/gm, '')
            }
            return v.split(",")
        });
    }

    async onValidateInputs(csvData) {
        //console.log("onValidateInputs");

        if (!csvData) {
            this.setState({
                isPageOneBtnDisabled: true,
                batchLen: 0,
                csvData: csvData,
                csvArray: [],
                uploadTotal: 0,
                addresses: [],
                values: [],
                duplicateAddr: [],
                invalidRows: []
            })
        }
        if (!csvData) return false;

        let allValuesValid = null;
        let values = null;

        const csvArray = this.csvDataToArrays(csvData) || [];
        const addresses = csvArray.map((vals) => vals[0].trim());

        try {
            values = csvArray.map((vals) => {
                if (vals.length === 1) return "-1"; // No value has been supplied
                if (vals.length === 2 && !vals[1]) return "-1"; // Empty value supplied
                if (vals.length > 2) return "-1"; // Too many delimiters / values supplied
                // return ethers.utils.parseUnits(vals[1].trim(), this.decimals);
                return vals[1];
            });
            //console.log("values", values);
            const invalidValues = values.filter(
                (value) => value.toString() === "-1"
            );
            allValuesValid = !invalidValues.length;
        } catch (e) {
            //console.error(e);
            allValuesValid = false;
        }

        let allAddressesValid = addresses.every((addrToCheck) => {
            if (!addrToCheck) return false;
            try {
                //console.log(addrToCheck)
                let web3 = new Web3();
                return web3.utils.isAddress(addrToCheck);
            } catch (e) {
                //console.error(e);
                return false;
            }
        });

        //console.log("Addresses", addresses);
        //console.log("Addresses VALID", allAddressesValid);

        //console.log("Values", values && values.map((value) => value.toString()));
        //console.log("Values VALID", allValuesValid);

        let valid = allAddressesValid && allValuesValid;

        let duplicateAddr = this.duplicateAddresses(csvArray);
        //console.log(duplicateAddr)

        if (valid) {
            let totalValue = new BigNumber(0);
            for (let i = 0; i < values.length; i++) {
                totalValue = totalValue.plus(new BigNumber(values[i]));
                //console.log(totalValue)
            }
            totalValue = Number(totalValue);
            let batchLen = Math.ceil(values.length / maticChargePer);

            this.setState({
                isPageOneBtnDisabled: this.state.selectedTokenId != -1 ? false : true,
                batchLen: batchLen,
                csvData: csvData,
                csvArray: csvArray,
                uploadTotal: totalValue,
                addresses: addresses,
                values: values,
                duplicateAddr: duplicateAddr,
                invalidRows: []
            })
        } else {
            let invalidRows = this.invalidRows(csvArray);
            //console.log(invalidRows)

            this.setState({
                isPageOneBtnDisabled: true,
                csvData: csvData,
                csvArray: csvArray,
                duplicateAddr: duplicateAddr,
                invalidRows: invalidRows
            });
        }
        // return allAddressesValid && allValuesValid;
    }

    async csvTextDataChange(event) {
        //console.log(event.target.value)
        this.setState({ csvData: event.target.value })
        await this.onValidateInputs(event.target.value)
    }

    duplicateAddresses(csvArray) {
        const duplicateAddresses = [];
        const addressCount = {};
        this.csvDataFilteredForValidRowsOnly(csvArray).forEach((row, idx) => {
            const address = row[0];
            if (!addressCount[address]) {
                addressCount[address] = 1;
            } else if (addressCount[address] === 1) {
                duplicateAddresses.push(address);
                addressCount[address] = addressCount[address] + 1;
            }
        });

        return duplicateAddresses;
    }

    csvDataFilteredForValidRowsOnly(csvArray) {

        return csvArray.filter((row) => {
            // Row invalid if true
            if (row.length === 1) {
                return false;
            }

            if (row.length === 2 && !row[1]) {
                // Empty value supplied
                return false;
            }

            if (row.length > 2) return false;

            const address = row[0] && row[0].trim() ? row[0].trim() : null;
            if (!address) return false;
            try {
                let web3 = new Web3();
                return web3.utils.isAddress(address);
            } catch (e) {
                return false;
            }
        });
    }

    invalidRows(csvArray) {
        const validRows = [];
        const invalidRows = [];
        csvArray.forEach((row, idx) => {
            // Row invalid if true
            if (row.length === 1) {
                invalidRows.push([
                    row[0] && row[0].trim() ? row[0].trim() : "Unknown",
                    "Unknown",
                    idx + 1,
                ]);
                return;
            }

            // Check address
            const address = row[0] && row[0].trim() ? row[0].trim() : null;
            if (!address) {
                invalidRows.push([...row, idx + 1]);
                return;
            }
            try {
                let web3 = new Web3();
                let isValid = web3.utils.isAddress(address);
                if (!isValid) {
                    invalidRows.push([...row, idx + 1]);
                }
            } catch (e) {
                invalidRows.push([...row, idx + 1]);
                return;
            }

            //Validate value supplied
            if (row.length === 2 && !row[1]) {
                // Empty value supplied
                invalidRows.push([row[0], "Unknown", idx + 1]);
                return;
            }

            if (row.length > 2) {
                // Too many delimiters / values supplied
                invalidRows.push(["Unknown", "Unknown", idx + 1]);
                return;
            }
        });

        return invalidRows;
    }

    async changeToken(e) {
        // console.log(e.target.value)
        if (e.target.value == -1) {
            toastr.error("Please select valid token.")
            this.setState({ isPageOneBtnDisabled: true })
            return;
        }
        let selectedToken = {};
        let token = e.target.value;
        let temp = await this.props.values.web3.eth.getBalance(this.props.values.account);
        let maticBalance = Number((Number(this.props.values.web3.utils.fromWei((temp).toString(), "ether"))).toFixed(2));
        let i = 0;

        for (let obj of this.state.tokens) {
            // if (i == 0) {
            //     maticBalance = (Number(obj.balance)).toFixed(2)
            // }
            if (obj.contract_ticker_symbol == token) {
                selectedToken = obj;
                selectedToken['index'] = i;
                selectedToken['maticBalance'] = maticBalance;
                let tokenBalance = Number(selectedToken['balance']) / (this.getDecimalsNum(selectedToken['contract_decimals']));
                selectedToken['tokenBalance'] = tokenBalance.toFixed(2);
                break;
            }
            i = i + 1;
        }
        // console.log(JSON.stringify(selectedToken))
        this.setState({
            isPageOneBtnDisabled: (this.state.csvArray && this.state.csvArray.length > 0) ? false : true,
            selectedTokenId: token,
            selectedTokenDetail: selectedToken
        })
    }

    getDecimalsNum(decimal) {
        let count = 1;
        for (let i = 0; i < decimal; i++) {
            count = count * 10;
        }
        return count;
    }

    getSelectOptionsToken() {
        if (this.state.tokens.length <= 0) {
            return;
        }
        return this.state.tokens.map((e, key) => {
            return <option key={key} value={e.contract_ticker_symbol}>{e.contract_name} ({e.contract_ticker_symbol})</option>;
        })
    }

    pageOneNextClick() {
        if (this.state.isPageOneBtnDisabled) {
            return;
        }
        if (this.state.selectedTokenDetail && this.state.selectedTokenId && this.state.csvArray && this.state.csvArray.length > 0) {
            if (this.state.selectedTokenDetail['contract_address'] && this.state.selectedTokenDetail['contract_ticker_symbol'] != "MATIC") {
                this.setState({ currentScreen: 0, isPageTwoBtnDisabled: false })
                this.allowance();
            } else if (!this.state.selectedTokenDetail['contract_address'] || this.state.selectedTokenDetail['contract_ticker_symbol'] == "MATIC") {
                this.setState({ currentScreen: 3, isPageThreeBtnDisabled: this.checkDataAtStageThree() })
            }
        } else {
            //console.log("No uploaded data found.");
            toastr.error("Something went wrong.")
        }
    }

    checkDataAtStageThree() {
        if (Number(this.state.selectedTokenDetail.maticBalance) <= 0) {
            return true;
        }

        if ((this.state.uploadTotal) > Number(this.state.selectedTokenDetail.tokenBalance)) {
            return true;
        }

        // if ((this.state.uploadTotal + (100 * this.state.batchLen)) > Number(this.state.selectedTokenDetail.tokenBalance)) {
        //     return true;
        // }

        if (Number(this.state.selectedTokenDetail.maticBalance) < (Number(maticChargeValue) + Number(0.0001))) {
            return true;
        }

        return false;
    }

    allowance() {
        try {
            if (this.props.values.isEnabled) {
                let web3_ERC20 = new this.props.values.web3.eth.Contract(LIST_ABI_ERC20, this.state.selectedTokenDetail.contract_address);
                // tronWebGlobal.contract().at(this.state.selectedTokenDetail.tokenId).then(async (contract) => {
                //     let contractGlobal = contract;
                web3_ERC20.methods.allowance(this.props.values.account, this.props.values.ADDRESS_MAIN).call().then(async (res) => {
                    let temp = this.state.selectedTokenDetail;
                    let decimal = (this.getDecimalsNum(this.state.selectedTokenDetail['contract_decimals']))

                    temp['remaining'] = res;
                    temp['remainingEth'] = (res / decimal).toFixed(0);
                    let isTokenGretter = (this.state.selectedTokenDetail.tokenBalance >= this.state.uploadTotal);
                    if (isTokenGretter && (Number(temp['remaining']) >= Number((this.state.uploadTotal * decimal).toFixed(0)))) {
                        setTimeout(() => {
                            this.setState({ currentScreen: 3, isPageThreeBtnDisabled: this.checkDataAtStageThree(), selectedTokenDetail: temp, isDirectPageThree: true });
                        }, 1000)
                    } else {
                        setTimeout(() => {
                            this.setState({ currentScreen: 2, selectedTokenDetail: temp, isPageTwoBtnDisabled: (isTokenGretter ? false : true), isDirectPageThree: false });
                        }, 1000)
                    }
                }, err => {
                    toastr.error(err.message);
                    this.setState({ currentScreen: 1 })
                })
                // }, err => {
                //     toastr.error(err);
                //     this.setState({ currentScreen: 1 })
                // });


            } else {
                //console.log("you are not connected.")
                toastr.info("You are not connected")
                this.setState({ currentScreen: 1 })
            }
        } catch (error) {
            console.log("Error occure::" + error)
            toastr.error("Somthing went wrong. Please try again.")
            this.setState({ currentScreen: 1 })
        }
    }

    pageTwoNextClick() {
        if (this.state.isPageTwoBtnDisabled) {
            return;
        }
        this.setState({ currentScreen: 0 });
        this.approve();
    }

    async backClicked(screenNum) {
        //console.log((screenNum == 2) + " ==>" + this.state.isDirectPageThree)
        if (screenNum == 2 && this.state.isDirectPageThree) {
            screenNum = 1;
        }
        await this.setState({ currentScreen: screenNum });
        this.checkScreen(screenNum)
    }

    checkScreen(screenNum) {
        if (screenNum == 1) {
            //console.log("YES:::")
            this.append_line_numbers('textarea-num')
        }
    }

    toPlainString(num) {
        return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
            function (a, b, c, d, e) {
                return e < 0
                    ? b + '0.' + Array(1 - e - c.length).join(0) + c + d
                    : b + c + d + Array(e - d.length + 1).join(0);
            });
    }

    approve() {
        try {

            if (this.props.values.isEnabled) {
                let web3_ERC20 = new this.props.values.web3.eth.Contract(LIST_ABI_ERC20, this.state.selectedTokenDetail.contract_address);

                let decimal = new BigNumber(this.getDecimalsNum(this.state.selectedTokenDetail['contract_decimals']));
                let valueList = [].concat(this.state.values);
                let total = new BigNumber(0);
                for (let i = 0; i < valueList.length; i++) {
                    total = total.plus((new BigNumber(valueList[i])).multipliedBy(decimal));
                }
                web3_ERC20.methods.approve(this.props.values.ADDRESS_MAIN, this.toPlainString(total)).send({
                    from: this.props.values.account,
                    value: 0
                }).then(async (res) => {
                    // //console.log(res)
                    setTimeout(() => {
                        this.setState({ currentScreen: 3, isPageThreeBtnDisabled: this.checkDataAtStageThree() });
                    }, 1000)
                }, err => {
                    toastr.error(err.message);
                    this.setState({ currentScreen: 2 })
                })
                // }, err => {
                //     toastr.error(err);
                //     this.setState({ currentScreen: 2 })
                // });


            } else {
                //console.log("you are not connected.")
                toastr.info("you are not connected.")
                this.setState({ currentScreen: 2 })
            }
        } catch (error) {
            //console.log("Error occure::" + error)
            toastr.error("Error occure::" + error)
            this.setState({ currentScreen: 2 })
        }
    }

    pageThreeNextClick() {
        if (this.state.isPageThreeBtnDisabled) {
            return;
        }
        let currentScreen = this.state.currentScreen;
        this.setState({ currentScreen: 0 })
        if (this.state.selectedTokenDetail && this.state.selectedTokenId && this.state.csvArray && this.state.csvArray.length > 0) {
            if (this.state.selectedTokenDetail['contract_address'] && this.state.selectedTokenDetail['contract_ticker_symbol'] != "MATIC") {
                //console.log("First:3")
                this.send(3);
            } else if (!this.state.selectedTokenDetail['contract_address'] || this.state.selectedTokenDetail['contract_ticker_symbol'] == "MATIC") {
                //console.log("Second:3")
                this.send(1)
            }
        } else {
            //console.log("No record found.")
            this.setState({ currentScreen: currentScreen });
        }
    }

    send(type) {
        try {
            if (this.props.values.isEnabled) {
                // console.log("this.props.values.ADDRESS_MAIN", this.props.values.ADDRESS_MAIN)
                let web3_Main = new this.props.values.web3.eth.Contract(LIST_ABI_MAIN, this.props.values.ADDRESS_MAIN);
                let tempDataAddr = [].concat(this.state.addresses);
                let tempDataValue = [].concat(this.state.values);
                for (let i = 0; i < this.state.batchLen; i++) {
                    let addressList = tempDataAddr.splice(0, maticChargePer);
                    let valueList = tempDataValue.splice(0, maticChargePer);
                    let decimal = new BigNumber(this.getDecimalsNum(this.state.selectedTokenDetail['contract_decimals']));
                    let total = new BigNumber(0);
                    let decimal18 = new BigNumber("1000000000000000000");
                    for (let i = 0; i < valueList.length; i++) {
                        let res = new BigNumber(valueList[i].toString()).multipliedBy(decimal);
                        total = total.plus(res);
                        valueList[i] = "0x" + res.toString(16);
                        console.log("utils.hexToNumber", this.props.values.web3.utils.toBN(valueList[i]).toString())
                    }
                    console.log("valueList[i]", valueList)
                    if (type == 1) {
                        let fee = (new BigNumber(maticChargeValue)).multipliedBy(decimal);
                        total = total.plus(fee);
                        web3_Main.methods.bulkSendMatic(addressList, valueList).send({
                            from: this.props.values.account,
                            value: total.toString(),
                            gasPrice: 0
                        }).then(async (res) => {
                            // console.log(res)
                            this.batchCompleteList.push(res);
                            this.batchCompleteLen = this.batchCompleteLen + 1;
                            if (this.state.batchLen == this.batchCompleteLen) {
                                this.setState({ currentScreen: 4 });
                            }
                        }, err => {
                            toastr.error(err.message);
                            this.setState({ currentScreen: 3 })
                        })
                    } else if (type == 3) {
                        let fee = (new BigNumber(maticChargeValue)).multipliedBy(decimal18);
                        web3_Main.methods.bulksendTokenSimple(this.state.selectedTokenDetail.contract_address, addressList, valueList).send({
                            from: this.props.values.account,
                            value: fee.toString(),
                            gasPrice: 0
                        }).then(async (res) => {
                            this.batchCompleteList.push(res);
                            this.batchCompleteLen = this.batchCompleteLen + 1;
                            if (this.state.batchLen == this.batchCompleteLen) {
                                this.setState({ currentScreen: 4 });
                            }
                        }, err => {
                            toastr.error(err.message);
                            this.setState({ currentScreen: 3 })
                        })
                    }

                }
                // }, err => {
                //     toastr.error(err);
                //     this.setState({ currentScreen: 3 })
                // });


            } else {
                //console.log("you are not connected.")
                toastr.info("you are not connected.")
                this.setState({ currentScreen: 3 })
            }
        } catch (error) {
            //console.log("Error occure::" + error)
            toastr.error("Error occure::" + error)
            this.setState({ currentScreen: 3 })
        }
    }

    pageFourNextClick() {
        // this.setState({ currentScreen: 1 });
        // window.location.reload();
        this.setToDefaultData();
    }

    getLinkList() {
        let link = '';
        if (this.props.values.chainId == 80001) {
            link = "https://mumbai.polygonscan.com/tx/"
        } else {
            link = "https://polygonscan.com/tx/"
        }
        return this.batchCompleteList.map((e, index, key) => {
            return <li className="list-group-item montserrat-normal-white-14px">
                Transaction {index + 1} successful
                <a href={link + e.transactionHash} target="_blank" className="float-right montserrat-medium-purple-heart-18px">Link</a>
            </li>;
        })
    }

    handleChange(event) {
        var num = new Number(event.target.value);
        if (!isNaN(num) && num >= 0) {
            let calcMatic = Math.ceil(num / maticChargePer) * Number(maticChargeValue);
            this.setState({ addrCount: num, calcMatic: calcMatic });
        }
    }

    scrollToReferralSystem() {
        var ele = document.getElementById("specials");
        window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }

    downloadSample(type) {
        //console.log("Download sample.");
        const rows = [
            ["0x67361A524Df98Af1dE26808E27d0E74A1F0b7a4F", "0.13"],
            ["0x8B14BEb458b885de64A16e5e9576729da38A28D0", "0.16"],
            ["0xcD43d0BD50B26F2E0064F77114DD0e5528c456d8", "0.99"],
            ["0xd360056DC45ab107d283c785ACdb87Efd4323646", "1.890"]
        ];

        let csvContent = type == 'csv' ? "data:text/csv;charset=utf-8," : "data:text/txt;charset=utf-8,";

        rows.forEach(function (rowArray) {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "sample." + type);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    getInvalidRows() {
        return this.state.invalidRows.map((e, key) => {
            return (<tr >
                <td className="p-2">{e[0]}</td>
                <td className="p-2">{e[1]}</td>
                <td className="p-2">{e[2]}</td>
            </tr>)
        })
    }

    getDuplicateAddr() {
        return this.state.duplicateAddr.map((e, key) => {
            return <tr>
                <td className="p-2">{e}</td>
            </tr>
        })
    }

    removeInvalidRows() {
        let csvData = this.csvArrayToString(
            this.csvDataFilteredForValidRowsOnly(this.state.csvArray)
        );
        this.onValidateInputs(csvData);
        setTimeout(() => {
            const ta = document.getElementById('textarea-num');
            const el = document.getElementById("text-nums");
            this.update_line_numbers(ta, el);
        }, 100)

    }

    csvArrayToString(csvArray) {
        return _.join(
            csvArray.map((row) => _.join(row, ",")),
            "\n"
        );
    }

    keepFirstAddressFromAddressDuplicates() {
        const firstAddressFound = {};
        const csvDataWithFirstDuplicateKept = this.csvDataFilteredForValidRowsOnly(this.state.csvArray).filter(
            (row) => {
                const address = row[0];
                let include = true;
                if (!firstAddressFound[address]) {
                    firstAddressFound[address] = true;
                } else {
                    include = !_.includes(this.duplicateAddresses(this.state.csvArray), row[0]);
                }

                return include;
            }
        );
        let csvData = this.csvArrayToString(csvDataWithFirstDuplicateKept);
        this.onValidateInputs(csvData);
        var ele = document.getElementById("upload");
        window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }

    combineBalancesFromAddressDuplicates() {
        const totalBalances = {};
        this.csvDataFilteredForValidRowsOnly(this.state.csvArray).forEach((row) => {
            const address = row[0];
            const value = parseFloat(row[1]);

            if (!totalBalances[address]) {
                totalBalances[address] = value;
            } else {
                totalBalances[address] = totalBalances[address] + value;
            }
        });

        let csvData = this.csvArrayToString(
            Object.keys(totalBalances).map((address) => [
                address,
                totalBalances[address],
            ])
        );

        this.onValidateInputs(csvData);

    }

    faqChanged(index) {
        if (this.state.currentFaq == index) {
            this.setState({
                currentFaq: -1
            })
        } else {
            this.setState({
                currentFaq: index
            })
        }
    }

    eventList = {}

    update_line_numbers(ta, el) {
        // Let's check if there are more or less lines than before
        const line_count = ta.value.split("\n").length;
        const child_count = el.children.length;
        let difference = line_count - child_count;
        // If there is any positive difference, we need to add more line numbers
        if (difference > 0) {
            // Create a fragment to work with so we only have to update DOM once
            const frag = document.createDocumentFragment();
            // For each new line we need to add,
            while (difference > 0) {
                // Create a <span>, add class name, append to fragment and
                // update difference
                const line_number = document.createElement("span");
                line_number.className = "my-num-line";
                frag.appendChild(line_number);
                difference--;
            }
            // Append fragment (with <span> children) to our wrapper element
            el.appendChild(frag);
        }
        // If, however, there's negative difference, we need to remove line numbers
        while (difference < 0) {
            // Simple stuff, remove last child and update difference
            el.removeChild(el.lastChild);
            difference++;
        }
    }

    append_line_numbers(id) {
        // Get reference to desired <textarea>
        const ta = document.getElementById(id);
        // If getting reference to element fails, warn and leave
        if (ta == null) {
            return //console.warn("[my-num] Couldn't find textarea of id '" + id + "'");
        }
        // If <textarea> already has  active, warn and leave
        if (ta.className.indexOf("my-num-active") != -1) {
            return //console.warn("[my-num] textarea of id '" + id + "' is already numbered");
        }
        // Otherwise, we're safe to add the class name and clear inline styles
        ta.classList.add("my-num-active");
        ta.style = {};

        // Create line numbers wrapper, insert it before <textarea>
        const el = document.createElement("div");
        el.className = "my-num-wrapper";
        el.id = "text-nums"
        ta.parentNode.insertBefore(el, ta);
        // Call update to actually insert line numbers to the wrapper
        this.update_line_numbers(ta, el);
        // Initialize event listeners list for this element ID, so we can remove
        // them later if needed
        this.eventList[id] = [];

        // Constant list of input event names so we can iterate
        const __change_evts = [
            "propertychange", "input", "keydown", "keyup"
        ];

        let that = this;
        // Default handler for input events
        const __change_hdlr = function (ta, el) {
            return function (e) {
                // If pressed key is Left Arrow (when cursor is on the first character),
                // or if it's Enter/Home, then we set horizontal scroll to 0
                // Check for .keyCode, .which, .code and .key, because the web is a mess
                // [Ref] stackoverflow.com/a/4471635/4824627
                if ((+ta.scrollLeft == 10 && (e.keyCode == 37 || e.which == 37
                    || e.code == "ArrowLeft" || e.key == "ArrowLeft"))
                    || e.keyCode == 36 || e.which == 36 || e.code == "Home" || e.key == "Home"
                    || e.keyCode == 13 || e.which == 13 || e.code == "Enter" || e.key == "Enter"
                    || e.code == "NumpadEnter")
                    ta.scrollLeft = 0;
                // Whether we scrolled or not, let's check for any line count updates
                that.update_line_numbers(ta, el);
            }
        }(ta, el);

        // Finally, iterate through those event names, and add listeners to
        // <textarea> and to events list
        /// TODO: Performance gurus: is this suboptimal? Should we only add a few
        /// listeners? I feel the update method is optimal enough for this to not
        /// impact too much things.
        for (let i = __change_evts.length - 1; i >= 0; i--) {
            ta.addEventListener(__change_evts[i], __change_hdlr);
            this.eventList[id].push({
                evt: __change_evts[i],
                hdlr: __change_hdlr
            });
        }

        // Constant list of scroll event names so we can iterate
        const __scroll_evts = ["change", "mousewheel", "scroll"];
        // Default handler for scroll events (pretty self explanatory)
        const __scroll_hdlr = function (ta, el) {
            return function () { el.scrollTop = ta.scrollTop; }
        }(ta, el);
        // Just like before, iterate and add listeners to <textarea> and to list
        /// TODO: Also just like before: performance?
        for (let i = __scroll_evts.length - 1; i >= 0; i--) {
            ta.addEventListener(__scroll_evts[i], __scroll_hdlr);
            this.eventList[id].push({
                evt: __scroll_evts[i],
                hdlr: __scroll_hdlr
            });
        }
    }

    getErrorMessage() {
        if (!(Number(this.state.selectedTokenDetail.maticBalance) > 0)) {
            return '* Insufficient Matic balance';
        } else if (Number(this.state.uploadTotal) > Number(this.state.selectedTokenDetail.tokenBalance)) {
            return '* Insufficient token balance';
            // } else if (Number(this.state.uploadTotal + (100 * this.state.batchLen)) > Number(this.state.selectedTokenDetail.tokenBalance)) {
            //     return '* Insufficient token balance';
        } else if (Number(this.state.selectedTokenDetail.maticBalance) < (Number(maticChargeValue) + Number(0.001))) {
            return '* Insufficient Matic balance for fee';
        }
    }

    checkConnection() {
        if (!this.props.values.isEnabled) {
            // toastr.info("Please connect your wallet.")
        }
    }

    render() {
        return (
            <div>
                {/* {this.state.currentScreen == 1 ? (
                    <section id="hero" className="d-flex align-items-center" style={{ height: 'auto', paddingTop: '75px', background: 'rgba(26,26,26,1)', backgroundSize: 'cover' }}>
                        <div className="container position-relative text-center" data-aos="zoom-in" data-aos-delay="100">
                            <div className="row">
                                <div className="col-md-12">
                                    <p style={{ paddingBottom: '45px' }}>
                                        Polygon Sender is a tool for distributing polygon(matic) network tokens to multiple wallet addresses from a CSV or TXT file</p>
                                </div>
                            </div>
                        </div>
                    </section>
                ) : ''} */}
                {this.state.currentScreen == 1 ? (
                    <main id="main">

                        <section id="hero" className="d-flex align-items-center" style={{ height: 'auto', paddingTop: '75px', background: 'rgba(26,26,26,1)', backgroundSize: 'cover' }}>
                            <div className="container position-relative text-center" data-aos="zoom-in" data-aos-delay="100" style={{ maxWidth: '100%' }}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <img src='/assets/images/audit_poly.png' style={{ width: '175px' }} />
                                    </div>
                                    <div className="col-md-6" style={{ alignSelf: 'center' }}>
                                        <p style={{ paddingBottom: '0px', fontWeight: '400' }}>
                                            Polygon Sender is a tool for distributing polygon(matic) network tokens to multiple wallet addresses from a CSV or TXT file</p>
                                    </div>
                                    <div className="col-md-3"></div>
                                </div>
                            </div>
                        </section>

                        <section id="about" className="about">
                            <div className="container" data-aos="fade-up">

                                <div className="row">
                                    <div className="col-md-12" data-aos="zoom-in" data-aos-delay="100">
                                        <div className="token-wrap">

                                            <div className="token-form">
                                                <div className="form-row">
                                                    <div className="col-md-12" style={{ textAlign: 'center', marginBottom: '30px' }}>
                                                        <span className="montserrat-semi-bold-hint-of-red-24px">
                                                            Get started here
                                                        </span>
                                                        <br />
                                                        <span className="montserrat-normal-hint-of-red-14px">Select token and add data to proceed</span>
                                                    </div>

                                                    <div className="col-md-12 form-group" onClick={() => this.checkConnection()}>
                                                        <select name="token" id="token" className="form-control" value={this.state.selectedTokenDetail ? this.state.selectedTokenDetail['contract_ticker_symbol'] : -1} onChange={(e) => this.changeToken(e)}>
                                                            <option key={-1} value={-1}>Select a Token</option>
                                                            {this.getSelectOptionsToken()}
                                                        </select>
                                                        <div className="validate"></div>
                                                    </div>

                                                    <div className="col-md-6 box-data">
                                                        <p>Token</p>
                                                        <div className="tbx">
                                                            <span>{this.state.selectedTokenDetail.contract_name ? this.state.selectedTokenDetail.contract_name + "(" + this.state.selectedTokenDetail.contract_ticker_symbol + ")" : 'Matic (MATIC)'}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 box-data">
                                                        <p>Decimals</p>
                                                        <div className="tbx">
                                                            <span>{this.state.selectedTokenDetail.contract_decimals ? this.state.selectedTokenDetail.contract_decimals : 0}</span>
                                                        </div>
                                                    </div>



                                                    <div className="col-md-12 form-group" id="upload" style={{ paddingBottom: '0', marginBottom: '0' }}>
                                                        {!this.state.isViewChange ? (<div className="tbx" style={{ height: '200px', textAlign: 'center', marginBottom: '0' }} onClick={() => { $('#file_upload').trigger('click') }}>
                                                            <FileDrop
                                                                onDrop={(files, event) => this.handleFileUpload(files, event)}
                                                                style={{ height: '200px' }}
                                                            >

                                                                <p style={{ paddingTop: '65px', fontSize: '18px' }}>Drag & drop the CSV</p>
                                                                <p style={{ fontSize: '18px' }}>.. or click to select a file from your computer</p>
                                                            </FileDrop>
                                                        </div>) : ''}

                                                        {this.state.isViewChange ?
                                                            (
                                                                <div className="t-wrapper" style={{ height: '200px' }}>
                                                                    <textarea id="textarea-num" className="banana-cake" value={this.state.csvData} onChange={(event) => { this.csvTextDataChange(event) }}></textarea>
                                                                </div>
                                                            ) : ''
                                                        }

                                                        <div className="validate"></div>
                                                    </div>
                                                    {this.state.isViewChange ?
                                                        (
                                                            <div className="col-md-12 uploadInsert">
                                                                <span className="upload-text" onClick={() => this.changeView(false)}>Upload File</span>
                                                            </div>
                                                        ) : (
                                                            <div className="col-md-12 uploadInsert">
                                                                <span className="upload-text" onClick={() => this.changeView(true)}>Insert manually</span>
                                                            </div>
                                                        )
                                                    }

                                                    <div className="col-md-6 form-group">
                                                        <div className="btns">
                                                            <input type="file" id="file_upload" ref={fileInput => (this.fileInput = fileInput)} style={{ display: 'none' }} onChange={(event) => { this.handleFileUpload(event.target.files, event) }} />
                                                            <a id="OpenFileUpload" onClick={() => { $('#file_upload').trigger('click') }} className="btn-gen animated fadeInUp scrollto" >Upload CSV</a>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6 form-group">
                                                        <div className="btns" style={{ float: 'right', fontSize: '18px' }}>
                                                            Download Sample  <a onClick={() => { this.downloadSample('csv') }} className="btn-gen animated fadeInUp scrollto downloadCSV" style={{ padding: '0' }}>CSV</a> / <a onClick={() => { this.downloadSample('txt') }} className="btn-gen animated fadeInUp scrollto downloadCSV" style={{ padding: '0' }}>TXT</a>
                                                        </div>
                                                    </div>

                                                    {(this.state.invalidRows && this.state.invalidRows.length > 0) ? (<div className="col-md-12">
                                                        <div className="my-2 alert alert-danger">
                                                            <div>
                                                                The following rows will not be part of the PolygonSender as they are invalid:
                                                            </div>
                                                            <table className="table-striped my-3" style={{ width: '100%' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th className="p-2">Address</th>
                                                                        <th className="p-2">Value</th>
                                                                        <th className="p-2">Line</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.getInvalidRows()}
                                                                </tbody>
                                                            </table>
                                                            <div>
                                                                <button className="delete_btn" onClick={() => { this.removeInvalidRows() }}>Delete all invalid</button>
                                                            </div>
                                                        </div>
                                                    </div>) : ''}

                                                    {(this.state.duplicateAddr && this.state.duplicateAddr.length > 0) ? (<div className="col-md-12">
                                                        <div className="my-2 alert alert-danger">
                                                            <div>Duplicate addresses have been found:</div>
                                                            <table className="table-striped my-3" style={{ width: '100%' }}>
                                                                <tbody>
                                                                    {this.getDuplicateAddr()}
                                                                </tbody>
                                                            </table>
                                                            <div style={{ textAlign: 'center' }}>
                                                                <button className="link_btn" onClick={() => { this.keepFirstAddressFromAddressDuplicates() }}>Keep the first one</button>|
                                                                <button className="link_btn" onClick={() => { this.combineBalancesFromAddressDuplicates() }}>Combine Balances</button>
                                                            </div>
                                                        </div>
                                                    </div>) : ''}
                                                </div>

                                                <div className="text-center">
                                                    {this.state.isPageOneBtnDisabled ?
                                                        (<button className="approve_send_btn" disabled={this.state.isPageOneBtnDisabled}>Approve & Send</button>)
                                                        :
                                                        (<button className="approve_send_btn" disabled={this.state.isPageOneBtnDisabled} onClick={() => this.pageOneNextClick()} style={{ background: 'green' }}>Approve & Send</button>)
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </section>

                        <section id="why-us" className="why-us">
                            <div className="container map_space" data-aos="fade-up">

                                <div className="section-title">
                                    <h2>Tutorial Video</h2>
                                </div>

                                <div className="row">

                                    <div className="col-lg-12">

                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/xwuuK5g1Lyg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                        {/* <h3>Coming soon</h3> */}

                                    </div>

                                </div>

                            </div>
                        </section>

                        <section id="menu" className="menu section-bg">
                            <div className="container map_space" data-aos="fade-up">

                                <div className="section-title">
                                    <h2>Fee Calculator</h2>
                                </div>

                                <div className="row" data-aos="fade-up" data-aos-delay="100">
                                    <div className="col-md-12">
                                        <div className="calc-wrap">

                                            <p className="montserrat-normal-white-14px">Enter the number of addresses:</p>

                                            <div action="" method="post" role="form" className="email-form">
                                                <div className="form-row">
                                                    <div className="col-md-6 form-group" style={{ marginTop: '10px' }}>
                                                        <input type="text" id="fee_input" name="name" className="form-control" placeholder={maticChargePer} value={this.state.addrCount} onChange={this.handleChange.bind(this)}
                                                            data-msg="Please enter at least 4 chars" />
                                                        <div className="validate"></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <p className="montserrat-normal-white-14px">Rate: {maticChargeValue} MATIC / 100 addresses</p>

                                            <p className="fees montserrat-normal-white-14px" style={{ marginTop: '10px', fontWeight: '600' }}>Fees: {this.state.calcMatic} MATIC + network charges</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row menu-container" data-aos="fade-up" data-aos-delay="200">

                                </div>

                            </div>
                        </section>

                        <section id="specials" className="specials">
                            <div className="container map_space" data-aos="fade-up">

                                <div className="section-title">
                                    <h2>Referral System</h2>
                                </div>

                                <div className="row" data-aos="fade-up" data-aos-delay="100">
                                    <div className="col-md-12 refe_block">
                                        <p>Coming Soon</p>
                                        {/* <p>Earn MATIC by referring people to PolygonSender.com</p>

                                        <p>Insert the Polygon address you would like to receive your MATIC tokens, and generate your unique referral address.</p>

                                        <p>You will earn 0.4 MATIC every time someone uses Polygon Sender using your unique referral link.</p>


                                        <div className="btns">
                                            <a href="#" className=" animated fadeInUp scrollto ref_link_btn montserrat-semi-bold-hint-of-red-24px">Generate referral link</a>
                                        </div>


                                        <div action="" method="post" role="form" className="email-form">
                                            <div className="form-row">
                                                <div className="col-md-6 form-group">
                                                    <input type="text" name="name" className="form-control" id="ref_link" placeholder="Input polygon address"
                                                        data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                                                    <div className="validate"></div>
                                                </div>
                                            </div>
                                        </div> */}

                                    </div>

                                </div>

                            </div>
                        </section>

                        <div className="container map_space" style={{ marginTop: '80px' }}>
                            <div className="col-md-12">
                                <h2 className="faq-title">FAQs</h2>
                                <div id="accordionExample" className="accordion mt-5">
                                    <div className="card">

                                        <header role="tab" className="card-header" onClick={() => { this.faqChanged(1) }}>
                                            <h2 className="mb-0">
                                                <button type="button" className="btn btn-link montserrat-medium-white-24px card-header-text"
                                                    aria-controls="collapseOne" aria-expanded="true"> What does Polygon Sender do? </button></h2>
                                        </header>
                                        <div id="collapseOne" role="tabpanel" style={{ display: (this.state.currentFaq == 1 ? 'block' : 'none') }} className="collapse show">
                                            <div className="card-body">
                                                <p className="montserrat-medium-white-24px">Polygon Sender is a tool for bulk sending tokens from a csv or txt file using our Token batch sending tool.</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="card">
                                        <header role="tab" className="card-header" onClick={() => { this.faqChanged(2) }}>
                                            <h2 className="mb-0"><button type="button"
                                                className="btn btn-link montserrat-medium-white-24px card-header-text" aria-controls="collapseTwo" aria-expanded="true"> How does Polygon Sender work?</button></h2>
                                        </header>
                                        <div id="collapseTwo" role="tabpanel" style={{ display: (this.state.currentFaq == 2 ? 'block' : 'none') }} className="collapse show">
                                            <div className="card-body">
                                                <p className="montserrat-medium-white-24px">You can send  Polygon tokens to multiple wallet addresses in 3 steps. </p>
                                                <br />
                                                <p className="montserrat-medium-white-24px"> Step 1. Upload CSV </p>
                                                <p className="montserrat-medium-white-24px"> Step 2. Approve tokens </p>
                                                <p className="montserrat-medium-white-24px"> Step 3. Send tokens</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="card">
                                        <header role="tab" className="card-header" onClick={() => { this.faqChanged(3) }}>
                                            <h2 className="mb-0"><button type="button"
                                                className="btn btn-link montserrat-medium-white-24px card-header-text" aria-controls="collapseThree" aria-expanded="true"> Select Token is showing  “No matching options”? </button></h2>
                                        </header>
                                        <div id="collapseThree" role="tabpanel" className="collapse show" style={{ display: (this.state.currentFaq == 3 ? 'block' : 'none') }}>
                                            <div className="card-body">
                                                <p className="montserrat-medium-white-24px">This is because you are not connected to your Polygon network or don’t have any tokens to select in your connected wallet - Please check your wallet is connected and you are connected to the address with the tokens you wish to send.</p>
                                                <p className="montserrat-medium-white-24px">Please check your wallet is connected by pressing the connect button in the top right hand corner of the site, and make sure you are connected to the wallet you intend to send tokens from.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <header role="tab" className="card-header" onClick={() => { this.faqChanged(4) }}>
                                            <h2 className="mb-0"><button type="button"
                                                className="btn btn-link montserrat-medium-white-24px card-header-text" aria-controls="collapseFour" aria-expanded="true"> How do I populate the CSV File?</button></h2>
                                        </header>
                                        <div id="collapseFour" role="tabpanel" className="collapse show" style={{ display: (this.state.currentFaq == 4 ? 'block' : 'none') }}>
                                            <div className="card-body">
                                                <p className="montserrat-medium-white-24px">The CSV file should be populated with 2 columns - Address, value You can download a pre populated example here</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <header role="tab" className="card-header" onClick={() => { this.faqChanged(5) }}>
                                            <h2 className="mb-0"><button type="button"
                                                className="btn btn-link montserrat-medium-white-24px card-header-text" aria-controls="collapseFive" aria-expanded="true"> Is there a maximum amount of addresses I can send to? </button></h2>
                                        </header>
                                        <div id="collapseFive" role="tabpanel" className="collapse show" style={{ display: (this.state.currentFaq == 5 ? 'block' : 'none') }}>
                                            <div className="card-body">
                                                <p className="montserrat-medium-white-24px">No - You can upload as many addresses as you require - however you will be asked to sign a new send transaction for every 200 addresses. This will be prompted automatically by the platform.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <header role="tab" className="card-header" onClick={() => { this.faqChanged(6) }}>
                                            <h2 className="mb-0"><button type="button"
                                                className="btn btn-link montserrat-medium-white-24px card-header-text" aria-controls="collapseSix" aria-expanded="true"> Do you have a referral programme? </button></h2>
                                        </header>
                                        <div id="collapseSix" role="tabpanel" className="collapse show" style={{ display: (this.state.currentFaq == 6 ? 'block' : 'none') }}>
                                            <div className="card-body">
                                                <p className="montserrat-medium-white-24px">
                                                    Yes - you can find more information on our affiliate page
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <header role="tab" className="card-header" onClick={() => { this.faqChanged(7) }}>
                                            <h2 className="mb-0"><button type="button"
                                                className="btn btn-link montserrat-medium-white-24px card-header-text" aria-controls="collapseFive" aria-expanded="true"> Can I revoke approval for tokens? </button></h2>
                                        </header>
                                        <div id="collapseFive" role="tabpanel" className="collapse show" style={{ display: (this.state.currentFaq == 7 ? 'block' : 'none') }}>
                                            <div className="card-body">
                                                <p className="montserrat-medium-white-24px">Yes if you have approved tokens but decided not to send them please contact a Polygon  Sender admin and we can revoke this approval for you.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </main >
                ) : ''}

                {
                    this.state.currentScreen == 2 ? (
                        <main id="main">
                            <section id="about" className="about" style={{ marginTop: '100px' }}>
                                <div className="container" data-aos="fade-up">
                                    <div className="row">
                                        <div className="col-md-12" data-aos="zoom-in" data-aos-delay="100">
                                            <div className="token-wrap" style={{ maxWidth: '1000px' }}>
                                                <div action="" method="post" role="form" className="token-form">
                                                    <div className="form-row" style={{ textAlign: 'center' }}>
                                                        <div className="col-md-12 form-group">
                                                            {/* <p> */}
                                                            <b>
                                                                <p className="montserrat-semi-bold-hint-of-red-24px" style={{ textAlign: 'center' }}>Approval</p>
                                                            </b>
                                                            <br />
                                                            <p>
                                                                This will authorise the amount of tokens you wish to send - once you have completed this step you will be taken to the next step where you will send your tokens.
                                                            </p>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="tbx box_padding">
                                                                <span className="montserrat-medium-purple-heart-24px">{this.state.selectedTokenDetail.remainingEth}</span>
                                                                <p className="box_text montserrat-normal-white-14px">Your current PolygonSender approval</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="tbx box_padding">
                                                                <span className="montserrat-medium-purple-heart-24px">{this.state.uploadTotal.toLocaleString('fullwide', { useGrouping: false })}</span>
                                                                <p className="box_text montserrat-normal-white-14px">Total Number of tokens to send </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="tbx box_padding">
                                                                <span className="montserrat-medium-purple-heart-24px">{this.state.selectedTokenDetail.tokenBalance}</span>
                                                                <p className="box_text montserrat-normal-white-14px"> Your token balance</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12 error">
                                                            {(this.state.selectedTokenDetail.tokenBalance >= this.state.uploadTotal) ? '' : '* Insufficient token balance'}
                                                        </div>
                                                    </div>
                                                    <div className="text-center"><button className="btn common_btn" onClick={() => this.backClicked(1)}>BACK</button>
                                                        &nbsp;
                                                        <button className="btn common_btn" disabled={this.state.isPageTwoBtnDisabled} onClick={() => this.pageTwoNextClick()}>NEXT</button></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </main>
                    ) : ''
                }

                {
                    this.state.currentScreen == 3 ? (
                        <main id="main">
                            <section id="about" className="about" style={{ marginTop: '100px' }}>
                                <div className="container" data-aos="fade-up">

                                    <div className="row">
                                        <div className="col-md-12" data-aos="zoom-in" data-aos-delay="100">
                                            <div className="token-wrap" style={{ maxWidth: '1000px' }}>

                                                <div action="" method="post" role="form" className="token-form">
                                                    <div className="form-row" style={{ textAlign: 'center' }}>
                                                        <div className="col-md-12 form-group">
                                                            {/* <p> */}
                                                            <b>
                                                                <p className="montserrat-semi-bold-hint-of-red-24px" style={{ textAlign: 'center' }}>Send Tokens</p>
                                                            </b>
                                                            <br />
                                                            {/* </p> */}
                                                            <p>
                                                                Check your Polygon send information below, one happy all the information is correct, click send to confirm your transactions.
                                                            </p>

                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="tbx box_padding">
                                                                <span className="montserrat-medium-purple-heart-24px">{this.state.csvArray ? this.state.csvArray.length : 0}</span>
                                                                <p className="box_text montserrat-normal-white-14px">Total number of addresses</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="tbx box_padding">
                                                                <span className="montserrat-medium-purple-heart-24px">{this.state.uploadTotal.toLocaleString('fullwide', { useGrouping: false })}</span>
                                                                <p className="box_text montserrat-normal-white-14px">Total number of tokens to send</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="tbx box_padding">
                                                                <span className="montserrat-medium-purple-heart-24px">{this.state.selectedTokenDetail.tokenBalance}</span>
                                                                <p className="box_text montserrat-normal-white-14px"> Your token balance</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="tbx box_padding">
                                                                <span className="montserrat-medium-purple-heart-24px">{this.state.batchLen}</span>
                                                                <p className="box_text montserrat-normal-white-14px">Total number of transactions needed</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="tbx box_padding" >
                                                                <span className="montserrat-medium-purple-heart-24px">{this.state.selectedTokenDetail.maticBalance}</span>
                                                                <p className="box_text montserrat-normal-white-14px">Your MATIC balance</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="tbx box_padding">
                                                                <span className="montserrat-medium-purple-heart-24px">{this.state.batchLen * maticChargeValue} Matic + network fees</span>
                                                                <p className="box_text montserrat-normal-white-14px">Cost of operation</p>

                                                            </div>
                                                        </div>

                                                        <div className="col-md-12 error">
                                                            {this.getErrorMessage()}
                                                        </div>
                                                    </div>
                                                    <div className="text-center">
                                                        <button className="btn common_btn" onClick={() => this.backClicked(2)}>BACK</button>
                                                        &nbsp;
                                                        <button className="btn common_btn" disabled={this.state.isPageThreeBtnDisabled} onClick={() => this.pageThreeNextClick()}>NEXT</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>
                        </main>
                    ) : ''
                }

                {
                    this.state.currentScreen == 4 ? (
                        <main id="main">
                            <section id="about" className="about" style={{ marginTop: '100px' }}>
                                <div className="container" data-aos="fade-up">
                                    <div className="row">
                                        <div className="col-md-12" data-aos="zoom-in" data-aos-delay="100">
                                            <div className="token-wrap" style={{ maxWidth: '1000px' }}>
                                                <div className="token-form">
                                                    <div className="form-row" style={{ textAlign: 'center' }}>
                                                        <div className="col-md-12 form-group">
                                                            <p>
                                                                <span class="montserrat-semi-bold-hint-of-red-24px">Congratulations! Polygon send complete.</span>
                                                                <br />
                                                                <br />
                                                                <span class="montserrat-normal-hint-of-red-14px">View your transactions on the Polygon explorer</span>
                                                            </p>
                                                        </div>
                                                        <section className="main_wrapp multisend" style={{ width: '100%', paddingTop: '0px' }}>
                                                            <div className="container">

                                                                <ul className="list-group mt-4 mb-4" style={{ textAlign: 'left' }}>
                                                                    {this.getLinkList()}
                                                                </ul>
                                                                <button className="btn common_btn" onClick={() => this.pageFourNextClick()}>Return to Home</button>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>
                        </main>
                    ) : ''
                }

                {
                    this.state.currentScreen == 0 ? (
                        <main id="main">
                            <section id="about" className="about" style={{ marginTop: '100px' }}>
                                <div className="container" data-aos="fade-up">

                                    <div className="row">
                                        <div className="col-md-12" data-aos="zoom-in" data-aos-delay="100">
                                            <div className="token-wrap" style={{ maxWidth: '1000px' }}>

                                                <div action="" method="post" role="form" className="token-form">
                                                    <div className="form-row" style={{ textAlign: 'center' }}>
                                                        <div className="col-md-12 form-group">

                                                        </div>

                                                        <div className="container d-flex align-items-center">
                                                            <a className="logo ml-auto mr-auto"><img src="assets/images/group-1@2x.svg" style={{ height: '124px', width: '141px' }} alt=""
                                                                className="img-fluid" /></a>
                                                        </div>

                                                        <div className="col-md-12 form-group" style={{ marginTop: '26px' }}>
                                                            <h1 className="montserrat-semi-bold-hint-of-red-24px">LOADING...</h1>
                                                            <br />
                                                            <p class="montserrat-normal-hint-of-red-14px">This can take several minutes. Please be patient and do not close this page.</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className="text-right"><button type="submit"><i className="icofont-chat">  Send a message</i></button></div> */}
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>
                        </main>
                    ) : ''
                }


            </div >
        )
    }
}